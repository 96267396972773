import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Navbar from "./components/layout/Navbar";
// import Landing from "./components/pages/Landing";
// import Services from "./components/pages/Services";
// import OurTeam from "./components/pages/OurTeam";
import Footer from "./components/layout/Footer";
import Assessment from "./components/pages/Assessment";
// import Resources from "./components/pages/Resources";
// import Donate from "./components/pages/Donate";

const Landing = lazy(() => import("./components/pages/Landing"));
const Services = lazy(() => import("./components/pages/Services"));
const OurTeam = lazy(() => import("./components/pages/OurTeam"));
// const Resources = lazy(() => import("./components/pages/Resources"));
const Donate = lazy(() => import("./components/pages/Donate"));

function App() {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <ErrorBoundary onReset={() => navigate("/")}>
              <Suspense>
                <Landing />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          exact
          path="/our-services"
          element={
            <ErrorBoundary onReset={() => navigate("/")}>
              <Suspense>
                <Services />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          exact
          path="/donate"
          element={
            <ErrorBoundary onReset={() => navigate("/")}>
              <Suspense>
                <Donate />
              </Suspense>
            </ErrorBoundary>
          }
        />
        {/* <Route
          exact
          path="/resources"
          element={
            <ErrorBoundary onReset={() => navigate("/")}>
              <Suspense>
                <Resources />
              </Suspense>
            </ErrorBoundary>
          }
        /> */}
        <Route
          exact
          path="/our-team"
          element={
            <ErrorBoundary onReset={() => navigate("/")}>
              <Suspense>
                <OurTeam />
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          exact
          path="/assessment"
          element={
            <ErrorBoundary onReset={() => navigate("/")}>
              <Suspense>
                <Assessment />
              </Suspense>
            </ErrorBoundary>
          }
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
