import React, { useState } from "react";
import { Link } from "react-router-dom";

// SCROLL TO TOP
// const scrollToTop = () => {
//   window.scrollTo(0, 0);
// };

// export default function Navbar() {

const Navbar = () => {
  // State to manage the collapse state of the navbar
  const [collapseOpen, setCollapseOpen] = useState(false);

  // Function to toggle the collapse state of the navbar
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  // Function to close the navbar when a link is clicked and scroll to top
  const closeNavbar = () => {
    if (collapseOpen) {
      setCollapseOpen(false);
    }
    // Smooth scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top mask-custom shadow-0">
      <div className="container">
        <Link
          className="navbar-brand fw-bold display-2"
          to="/"
          onClick={closeNavbar}
        >
          <span style={{ color: "#008080" }}>L</span>
          <span style={{ color: "#008080" }}>W</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleCollapse}
        >
          <i className="fas fa-bars"></i>
        </button>
        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
        <div
          className={"collapse navbar-collapse" + (collapseOpen ? " show" : "")}
        >
          {/* <div className="container-fluid"> */}
          <ul className="navbar-nav me-auto justify-content-center">
            <li className="nav-item">
              <Link
                className="nav-link fs-4 fw-bold text-dark"
                to="/our-services"
                onClick={closeNavbar}
                style={{ color: "#008080" }}
              >
                <h4 className="mt-2" style={{ color: "#008080" }}>
                  Services
                </h4>
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link fs-4 fw-bold text-dark" to="/resources">
                <h4 className="mt-2" style={{ color: "#deb944" }}>
                  Resources
                </h4>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className="nav-link fs-4 fw-bold text-dark"
                to="/our-team"
                onClick={closeNavbar}
                style={{ color: "#008080" }}
              >
                <h4 className="mt-2" style={{ color: "#008080" }}>
                  Team
                </h4>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link fs-4 fw-bold text-dark"
                to="/assessment"
                onClick={closeNavbar}
                style={{ color: "#008080" }}
              >
                <h4 className="mt-2" style={{ color: "#008080" }}>
                  Assessment
                </h4>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link fs-4 fw-bold text-dark"
                to="/donate"
                onClick={closeNavbar}
                style={{ color: "#deb944" }}
              >
                <h4 className="mt-2" style={{ color: "#008080" }}>
                  Donate
                </h4>
              </Link>
            </li>
            {/* <li className="nav-item" style={{ color: "#008080" }}>
              <a
                className="nav-link fs-4 fw-bold text-dark"
                href="https://www.therapyportal.com/p/selusconsultancy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="mt-2" style={{ color: "#008080" }}>
                  Therapy Portal
                </h4>
              </a>
            </li> */}
          </ul>
          {/* </div> */}
          <ul className="navbar-nav d-flex flex-row">
            <li className="nav-item me-3 me-lg-0">
              <a
                className="nav-link"
                href="https://calendly.com/selusconsultancy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="btn btn-secondary me-3 text-capitalize fs-4"
                  style={{ color: "#008080" }}
                >
                  Book a consult
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
