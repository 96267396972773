import React from "react";
import { Link } from "react-router-dom";

// SCROLL TO TOP
const scrollToTop = () => {
  window.scrollTo(0, 0);
};


export default function Footer() {

  const handleClick = () => {
    window.location.href = 'tel:+254722107231';
  };

  const phoneNumberStyle = {
    color: '#008080',
    cursor: 'pointer', // Set cursor to pointer for hover effect
  };

  return (
    <footer className="text-center text-lg-start bg-dark text-white mt-5 pt-5">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block fs-4 fw-bold">
          <span>
            Join us on social media and stay connected for inspiration, updates,
            and a vibrant community!
          </span>
        </div>

        <div>
          {/* <a
            class="btn btn-outline-light btn-floating m-1"
            href="https://www.linkedin.com/company/selus-consultancy/?originalSubdomain=ke"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-linkedin"></i>
          </a> */}

          <a
            class="btn btn-outline-light btn-floating m-1"
            href="https://www.instagram.com/lugo_wellness/"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-instagram"></i>
          </a>

          {/* <a
            class="btn btn-outline-light btn-floating m-1"
            href="https://twitter.com/selusconsultant"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-twitter"></i>
          </a>

          <a
            class="btn btn-outline-light btn-floating m-1"
            href="https://www.facebook.com/selusconsultants/"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-facebook-f"></i>
          </a> */}
        </div>
      </section>

      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-capitalize fw-bold mb-4">
                Lugo Wellness (Formerly Selus Consultancy)
              </h6>
              <p>
                We provide exceptional services to help individuals achieve
                personal growth and well-being. With compassionate therapists
                and evidence-based approaches, we guide clients on a
                transformative journey towards mental wellness, offering
                support, understanding, and the tools needed to thrive.
              </p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p>
                <Link
                  to="/our-services"
                  onClick={scrollToTop}
                  className="text-reset"
                >
                  Services
                </Link>
              </p>
              {/* <p>
                <Link
                  to="/resources"
                  onClick={scrollToTop}
                  className="text-reset"
                >
                  Resources
                </Link>
              </p> */}
              <p>
                <Link
                  to="/our-team"
                  className="text-reset"
                  onClick={scrollToTop}
                >
                  Team
                </Link>
              </p>
              <p>
                <Link to="/donate" className="text-reset" onClick={scrollToTop}>
                  Donate
                </Link>
              </p>
              <p>
                <a
                  href="https://calendly.com/selusconsultancy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset"
                >
                  Book A Consult
                </a>
              </p>
            </div>

            <div className="col-md-5 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <i
                  className="fas fa-home me-3"
                  style={{ color: "#008080" }}
                ></i>{" "}
                Greenhouse Mall, East Wing, 3rd floor
              </p>

              <p>
                <i
                  className="fas fa-envelope me-3"
                  style={{ color: "#008080" }}
                ></i>
                stacey@lugowellness.org
              </p>
              <p onClick={handleClick} style={phoneNumberStyle} className="fw-bold">
                <i
                  className="fas fa-phone me-3"
                  style={{ color: "#008080" }}
                ></i>{" "}
                +(254)-722-107-231
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        &copy; {new Date().getFullYear()} Copyright
      </div>
    </footer>
  );
}
