import React from "react";

export default function Assessment() {
  return (
    <div className="container mt-5 pt-5">
      <p className="text-dark text-center mb-5">
        Our comprehensive assessment services provide a deeper understanding of
        your unique needs and challenges. Our experienced team of psychologists
        and counselors utilizes evidence-based methods to assess your mental
        health, emotional well-being, and personal strengths. Through thorough
        assessments, we gain valuable insights to develop personalized treatment
        plans tailored to your specific goals. Take the first step towards
        personal growth and healing by scheduling an assessment with us today.
      </p>
      <dl className="row">
        <dt className="col-sm-6">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfKnRW26KHpBn7qMjypePx_A6zpS6tf2gokJ_t4Lw3GGxxc1w/viewform?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="fs-4"
            style={{ color: "#008080" }}
          >
            1. Burn Out
          </a>
        </dt>
        <dd className="col-sm-6">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeCqr7EGr98zrz_fnbUYhXB5uvreeRlEC-OABUCt0P5rHW7CQ/viewform?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="fs-4"
            style={{ color: "#008080" }}
          >
            2. Imposter Syndrome
          </a>
        </dd>
        <hr />
        <dt className="col-sm-6">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeWD6Xwptg6WvA-5pPf7mo_icltQYbQFz3oslW7hZnNQAZYyg/viewform?usp=sharing"
            className="fs-4"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#008080" }}
          >
            3. Bipolar
          </a>
        </dt>
        <dd className="col-sm-6 text-dark">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfNfox-aO-LX8g7xl5o7DuoGUvZsXYXkyYes-8Ke7E4mC9G4g/viewform?usp=sharing"
            className="fs-4"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#008080" }}
          >
            4. Anxiety
          </a>
        </dd>
        <hr />
        <dt className="col-sm-6">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSctxPzi2muGj3cvFvtP4ExNsAW9_aeFegLnuxuPctIy12Siqg/viewform?usp=sharing"
            className="fs-4"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#008080" }}
          >
            5. Grief
          </a>
        </dt>
        <dd className="col-sm-6 text-dark">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSf2_Br1kSJRHy6IB4Svaq6aaTtMIdMp4_zlvyO9nSWc4CP03A/viewform?usp=sharing"
            className="fs-4"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#008080" }}
          >
            6. General Efficiency
          </a>
        </dd>
        <hr />
        <dt className="col-sm-6">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScGv8Jga-HBrrV6UA4RduhfAAU0O-V09DZtGCDCtmeKOAV6Ew/viewform?usp=sharing"
            className="fs-4"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#008080" }}
          >
            7. PTSD/Trauma
          </a>
        </dt>
        <dd className="col-sm-6 text-dark">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeuIGp1s4yAEazBuzQN9Tc6KOJOjAlCESKMQ1fRuTm5n0cJXA/viewform?usp=sharing"
            className="fs-4"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#008080" }}
          >
            8. Stress Test
          </a>
        </dd>
      </dl>
    </div>
  );
}